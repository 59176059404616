import { useEffect, useState } from "react";

const Banner = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    isClient && (
      <div className="banner-home">
        <video preload="none" autoPlay muted loop playsInline controls={false}>
          <source src="./banner.webm" type="video/webm" />
        </video>
      </div>
    )
  );
};
export default Banner;

