import { ContentApi } from "@/api/content";
import { ShopApi } from "@/api/shop";
import Testimonial from "@/components/testimonial/Testimonial";
import { config } from "@/config/config";
import Banner from "@/pages/home/components/banner";
import { GetServerSideProps } from "next";
import "react-multi-carousel/lib/styles.css";
import Blog from "./home/components/blog";
import HowIdWorks from "./home/components/howIdWorks";
import ShopCategory from "./home/components/shopCategory";
import ShopProduct from "./home/components/shopProduct";

export const getServerSideProps: GetServerSideProps = async ({ preview = false }) => {
  let productList: ProductShopListDto[] = [];
  let categoryList: ShopCategoryListSimplifiedDto[] = [];
  let blogList: PostBlogListDto[] = [];
  let highlightsList: PostHighlightsListDto[] = [];

  try {
    await ShopApi.GetProductShopList({
      fixedOrSoon: true,
      noPagination: true,
    }).then((ret) => {
      productList = ret.result;
    });
  } catch (ex) {}

  try {
    await ShopApi.CategoryListSimplified({ noPagination: true }).then((ret) => {
      categoryList = ret.result;
    });
  } catch (ex) {}

  try {
    await ContentApi.GetBlogList({ limit: 3, channels: [config.contentChannelHome] }).then((ret) => {
      blogList = ret.result;
    });
  } catch (ex) {}

  try {
    await ContentApi.GetHighlightsList({ limit: 1 }).then((ret) => {
      highlightsList = ret.result;
    });
  } catch (ex) {}

  return {
    props: {
      productList: productList,
      categoryList: categoryList,
      blogList: blogList,
      highlightsList: highlightsList,
    },
  };
};

type Props = {
  productList: ProductShopListDto[];
  categoryList: ShopCategoryListSimplifiedDto[];
  blogList: PostBlogListDto[];
  highlightsList: PostHighlightsListDto[];
};

export default function Home({ productList, categoryList, blogList, highlightsList }: Props) {
  return (
    <div>
      <Banner />
      <ShopCategory categoryList={categoryList} />
      <HowIdWorks />
      <ShopProduct productList={productList} />
      {config.enableModules.showTestimony && (
        <div className="rbt-testimonial-area bg-color-extra2 rbt-section-gap">
          <Testimonial />
        </div>
      )}
      {config.enableModules.showNews && <Blog blogList={blogList} />}
    </div>
  );
}

