import { config } from "@/config/config";
import Image from "next/image";
import Link from "next/link";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ShopCategory = ({ categoryList }: { categoryList: ShopCategoryListSimplifiedDto[] }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    categoryList &&
    categoryList.length > 0 && (
      <div className="container text-center mb-5 card-category-course">
        <Carousel infinite autoPlay responsive={responsive}>
          {categoryList.map((item, index) => (
            <div key={index} className="col card-div p-3">
              <Link href={`${config.dash_shop}?category=${item.friendlyUrl}`}>
                <Image alt={item.name} src={item.thumbnail ?? config.emptyImage} width="0" height="0" sizes="100vw" className="w-100 h-100 rounded" />
              </Link>
            </div>
          ))}
        </Carousel>
      </div>
    )
  );
};

export default ShopCategory;

