import { config } from "@/config/config";
import Image from "next/image";
import Link from "next/link";

const Blog = ({ blogList }: { blogList: PostBlogListDto[] }) => {
  return (
    blogList &&
    blogList.length > 0 && (
      <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb--60">
              <div className="section-title text-center">
                <h2 className="title">Notícias Clinique</h2>
              </div>
            </div>
          </div>
          <div className="row g-5">
            {blogList.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="rbt-card variation-02 rbt-hover">
                  <div className="rbt-card-img">
                    <Link href={`/blog/post/${item.friendlyUrl}`}>
                      <Image src={item.thumbnail ?? config.emptyImage} width={450} height={267} priority alt={item.title} />
                    </Link>
                  </div>
                  <div className="rbt-card-body">
                    <h5 className="rbt-card-title">
                      <Link href={`/blog/post/${item.friendlyUrl}`}>{item.title}</Link>
                    </h5>
                    <p className="rbt-card-text">{item.release}</p>
                    <div className="rbt-card-bottom">
                      <Link className="transparent-button" href={`/blog/post/${item.friendlyUrl}`}>
                        Veja mais
                        <i>
                          <svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
                            <g stroke="#27374D" fill="none" fillRule="evenodd">
                              <path d="M10.614 0l5.629 5.629-5.63 5.629" />
                              <path strokeLinecap="square" d="M.663 5.572h14.594" />
                            </g>
                          </svg>
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default Blog;

