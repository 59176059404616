import { icons } from "lucide-react";
import React from "react";

type PropType = {
  name: string;
  color: string;
  size: number;
};

const Icon: React.FC<PropType> = (props) => {
  const LucideIcon = typeof props.name === "string" ? icons[props.name as keyof typeof icons] : icons[props.name];
  return <LucideIcon color={props.color} size={props.size} strokeWidth={1} />;
};

export default Icon;
