import Image from "next/image";
import Link from "next/link";

const testimonialFour = [
  {
    img: "/images/testimonial/client-01.png",
    title: "Martha Maldonado",
    position: "Executive Chairman",
    company: "@ Google",
    desc: "After the launch, vulputate at sapien sit amet, auctor iaculis lorem. In vel hend rerit.",
  },
  {
    img: "/images/testimonial/client-02.png",
    title: "Michael D. Lovelady",
    position: "CEO",
    company: "@ Google",
    desc: "Histudy education, vulputate at sapien sit amet, auctor iaculis lorem. In vel hend rerit. ",
  },
  {
    img: "/images/testimonial/client-03.png",
    title: "Valerie J. Creasman",
    position: "Executive Designer",
    company: "@ Google",
    desc: "Our educational, vulputate at sapien sit amet, auctor iaculis lorem. In vel hend rerit nisi.",
  },
];

const Testimonial = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 mb--60">
          <div className="section-title text-center">
            <h2 className="title">O que fala nossos alunos</h2>
          </div>
        </div>
      </div>
      <div className="row g-5">
        {testimonialFour.map((item, innerIndex) => (
          <div className="col-lg-4 col-md-6 col-12" key={innerIndex}>
            <div className="rbt-testimonial-box">
              <div className="inner">
                <div className="clint-info-wrapper">
                  <div className="thumb">
                    <Image src={item.img} width={494} height={494} alt="Clint Images" />
                  </div>
                  <div className="client-info">
                    <h5 className="title">{item.title}</h5>
                    <span>
                      {item.position} <i>{item.company}</i>
                    </span>
                  </div>
                </div>
                <div className="description">
                  <p className="subtitle-3">{item.desc}</p>
                  <div className="rating mt--20">
                    <Link href="#">
                      <i className="fa fa-star"></i>
                    </Link>
                    <Link href="#">
                      <i className="fa fa-star"></i>
                    </Link>
                    <Link href="#">
                      <i className="fa fa-star"></i>
                    </Link>
                    <Link href="#">
                      <i className="fa fa-star"></i>
                    </Link>
                    <Link href="#">
                      <i className="fa fa-star"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;

