import { config } from "@/config/config";
import Image from "next/image";
import Link from "next/link";

const ShopProduct = ({ productList }: { productList: ProductShopListDto[] }) => {
  return (
    <div className="rbt-rbt-card-area">
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2 className="title">Cursos em destaque</h2>
            </div>
          </div>
        </div>
        <div className="row row--15 row-gap-3 pt-5 flex">
          {productList &&
            productList.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-2 col-sm-2 col-6">
                <Link href={`${config.dash_shop}detalhe/${item.friendlyUrl}`} title={item.name} className="position-relative">
                  {item.soon && <Image alt={item.name} src="/novos/img/vem-ai.png" width="0" height="0" sizes="100vw" className="card-soon" />}
                  {item.soon === false && item.saleCampaign && item.saleCampaign.thumbnail && (
                    <Image alt={item.saleCampaign.name} src={item.saleCampaign.thumbnail} width="0" height="0" sizes="100vw" className="card-soon" />
                  )}
                  <Image alt={item.name} src={item.thumbnail ?? config.emptyImage} width="0" height="0" sizes="100vw" className="w-100 h-100 rounded" />
                </Link>
              </div>
            ))}
        </div>
        <div className="mt-5 text-center">
          <Link href={config.dash_shop} className="rbt-btn btn-secondary radius-round">
            Mais cursos
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShopProduct;

