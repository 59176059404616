import Icon from "@/components/icon";

const SowIdWorks = () => {
  const vantagens = [
    {
      title: "Sem tempo para estudar?",
      desc: "Nossas aulas vão direto ao ponto, pra você estudar no seu ritmo e aplicar o conhecimento, na prática.",
      img: "Lightbulb",
    },
    {
      title: "IA Nick",
      desc: "Uma assistente que cria um plano de estudos só para você.",
      img: "GraduationCap",
    },
    {
      title: "Materiais Complementares",
      desc: "E-books, banco de questões e PDFs para aprofundar seus conhecimentos.",
      img: "Youtube",
    },
    {
      title: "Professores Referência",
      desc: "Especialistas que ensinam o que realmente importa para sua formação.",
      img: "Trophy",
    },
  ];

  return (
    <div className="rbt-service-area bg-color-white pb-5 card-how-id-works">
      <div className="container">
        <div className="container">
          <div className="row mb--60">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2 className="title">Como funciona</h2>
                <h6>Tudo em uma plataforma simples e fácil de usar, para você estudar onde e quando quiser</h6>
              </div>
            </div>
          </div>
          <div className="row row--15 mt_dec--30">
            {vantagens.map((item, innerIndex) => (
              <div className="col-lg-4 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30" key={innerIndex}>
                <div className="service-card service-card-6">
                  <div className="inner">
                    <div className="icon">
                      <Icon name={item.img} color="black" size={50} />
                    </div>

                    <div className="content">
                      <h6 className="title">{item.title}</h6>
                      <p className="description">{item.desc}</p>
                    </div>
                    <span className="number-text">{innerIndex + 1}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SowIdWorks;

